<template>
  <div class="content-box sss">
    <div class="header" :class="{ active: headerScroll }">
      <div class="logo">
        <img src="../../assets/home/logo.png" alt="logo" />
      </div>
    </div>
    <div class="content">
      <div class="bannerBox">
        <img src="../../assets/home/banner.png" />
      </div>

      <!-- 产品介绍 -->
      <div class="introduceBOX wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="name">产品介绍</div>
          <div class="line"></div>
        </div>
        <div class="introduce">
          <div>收付通是一款为商户和服务商开发的APP，根据使用者的不同身份触达不同功能，</div>
          <div>为您提供安全、使捷、智能化服务，商户管理因此而变得更加高效。</div>
          <div>合作伙伴可通过App快速入网。</div>
        </div>
        <div class="userBox">
          <div class="serBox">
            <div class="imgBox">
              <img src="../../assets/home/Service.png" />
            </div>
            <div class="serText"><span>服务商:</span> 通过APP进行业务拓展。</div>
          </div>
          <div class="serBox right">
            <div class="imgBox mer">
              <img src="../../assets/home/Merchant.png" />
            </div>
            <div class="serText">
              <span>商户: </span>
              主要满足商户随时随地进行收款，配合POS
              <div class="ss">终端， 为商户提供POS收款服务。</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务商功能 -->
      <div class="serviceBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="name">服务商功能</div>
          <div class="line"></div>
        </div>
        <div class="itemBox">
          <div class="squareItem" v-for="(item, index) in serviceList" ::key="index">
            <div class="itemIcon">
              <img :src="item.icon" width="100%" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 商户主要功能 -->
      <div class="serviceBox merBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="name">商户主要功能</div>
          <div class="line"></div>
        </div>
        <div class="itemBox">
          <div class="squareItem" v-for="(item, index) in merList" ::key="index">
            <div class="itemIcon">
              <img :src="item.icon" width="100%" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="advantageBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="name">优势</div>
          <div class="line"></div>
        </div>
        <div class="picBox">
          <div class="itemImg">
            <img src="../../assets/home/POS.png" />
          </div>

          <div class="itemImg">
            <img src="../../assets/home/security.png" />
          </div>
        </div>
      </div>
      <!-- 版权 -->
      <div class="footer">
        <div class="info">
          <div class="company">公司：深圳市万商通信息技术有限公司</div>
          <div class="address">地址：深圳市龙华区民治街道民强社区优城北区AB座2101</div>
          <div>客服电话：400-700-6889</div>
        </div>
        <div class="copyright">
          <span>Copyright2023 收付通 版权所有</span>
          <span @click="go" class="copyrightNum">粤ICP备2022143768号-2</span>
        </div>
      </div>
    </div>
    <div class="backTop" v-if="btnFlag" @click="backTop"><img src="../../assets/home/top.png" /></div>
  </div>
</template>

<script>
import "animate.css";
import { WOW } from "wowjs";
export default {
  data() {
    return {
      headerScroll: false, //透明判断
      serviceList: [
        // {
        //   name: "商户入网",
        //   icon: require("../../assets/home/int.png"),
        // },
        {
          name: "商户查询",
          icon: require("../../assets/home/search.png"),
        },
        {
          name: "终端管理",
          icon: require("../../assets/home/terminal.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "分享",
          icon: require("../../assets/home/share.png"),
        },
      ],
      merList: [
        // {
        //   name: "快捷交易",
        //   icon: require("../../assets/home/quick.png"),
        // },
        {
          name: "交易结算查询",
          icon: require("../../assets/home/transaction.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "磁条大额认证",
          icon: require("../../assets/home/authentication.png"),
        },
        {
          name: "变更结算",
          icon: require("../../assets/home/change.png"),
        },
      ],
      advantageList: [
        {
          name: "移动POS",
          url: require("../../assets/home/POS.png"),
          dec: "外观小巧，方便携带，支持全部银联卡，支付宝，微信，云闪付。",
        },
        {
          name: "安全认证",
          url: require("../../assets/home/security.png"),
          dec: "通过银联，银行卡POS检测中心双重认证",
        },
      ],
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.$nextTick(() => {
      //页面渲染完，在执行
      let wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },

  methods: {
    go() {
      // location.href = "https://beian.miit.gov.cn/#/Integrated/index";
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    // 点击回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const upRoll = setInterval(() => {
        const top = document.documentElement.scrollTop; // 每次获取页面被卷去的部分
        const speed = Math.ceil(top / 10); // 每次滚动多少 （步长值）
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed; // 不在顶部 每次滚动到的位置
        } else {
          clearInterval(upRoll); // 回到顶部清除定时器
        }
      }, 10);
    },

    // 为了计算距离顶部的高度，当高度大于600显示回顶部图标，小于600则隐藏
    scrollToTop() {
      let that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.scrollTop > 92 ? (that.headerScroll = true) : (that.headerScroll = false);
      if (that.scrollTop > 600) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content-box {
  width: 100%;
  background: #ffffff;
  height: 100%;
  min-width: 1200px;
  .header {
    position: fixed;
    left: 0;
    top: 0;
    height: 92px;
    // background: transparent;
    background: #fff8f7;
    width: 100%;
    z-index: 9999;
    &.active {
      background: #fff;
    }
    .logo {
      margin-top: 18px;
      margin-left: 160px;
      width: 58px;
      height: 58px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    margin-top: 92px;
    position: relative;
    .bannerBox {
      width: 100%;
      height: 800px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .introduceBOX {
      height: 860px;
      padding: 0px 260px;
      box-sizing: border-box;
      background: #fff;

      .introduce {
        text-align: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 50px;
        line-height: 28px;
        letter-spacing: 1px;
      }
      .userBox {
        margin-top: 130px;
        display: flex;
        justify-content: space-between;
        .serBox {
          &:hover {
            transform: translateY(-6px);
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            box-shadow: 0 4px 8px 0 transparent;
          }
        }
        .imgBox {
          display: flex;
          justify-content: center;
          width: 540px;
          height: 228px;
          display: flex;

          img {
            display: block;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
        }
        .serText {
          width: 100%;
          font-size: 22px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          color: #86909c;
          padding: 16px 0;
          letter-spacing: 1px;
          display: inline-block;
          box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.06);
          border-radius: 0px 0px 10px 10px;
          padding-left: 26px;
          box-sizing: border-box;
          span {
            color: #4e5969;
          }
        }
      }
    }
    .serviceBox {
      height: 814px;
      padding: 0px 260px;
      box-sizing: border-box;
      background: #f9f9f9;
    }
    .merBox {
      background: #fff;
    }
    .advantageBox {
      height: 814px;
      padding: 0px 260px;
      box-sizing: border-box;
      background: #f9f9f9;
      .titleName {
        .line {
          width: 200px !important;
        }
      }
      .picBox {
        display: flex;
        justify-content: space-between;
        margin-top: 180px;
        .itemImg {
          width: 660px;
          height: 240px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .footer {
      height: 224px;
      padding-top: 68px;
      background: #ffffff;
      color: #86909c;
      font-size: 18px;
      text-align: center;
      box-sizing: border-box;

      .info {
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        .company,
        .address {
          margin-right: 22px;
        }
      }
      .copyrightNum {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .backTop {
    width: 75px;
    height: 75px;
    position: fixed;
    right: 32px;
    bottom: 130px;
    cursor: pointer;
    &:hover {
      animation: shake 800ms ease-in-out;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .introduceBOX,
  .serviceBox,
  .advantageBox {
    position: relative;
    .titleName {
      text-align: center;
      display: flex;
      padding-top: 66px;
      justify-content: center;
      position: relative;
      .name {
        font-size: 42px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        margin: 0px 20px;
        z-index: 99;
      }
      .line {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        height: 20px;
        background: #ffede6;
        border-radius: 40px;
      }
    }
    .itemBox {
      display: flex;
      justify-content: space-between;
      margin-top: 154px;
      .squareItem {
        width: 300px;
        height: 300px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 2px 0px 16px 0px rgba(0, 0, 0, 0.06);
        .itemIcon {
          display: flex;
          justify-content: center;
          padding-top: 56px;
          box-sizing: border-box;
          img {
            width: 128px;
            height: 128px;
          }
        }
        .text {
          margin-top: 22px;
          text-align: center;
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #4e5969;
        }
        &:hover {
          transform: translateY(-6px);
          transition-duration: 0.5s;
          box-shadow: 0 4px 8px 0 transparent;
        }
      }
    }
  }
  @keyframes shake {
    /* 水平抖动，核心代码 */
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(+2px, 0, 0);
    }
    30%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(+4px, 0, 0);
    }
    50% {
      transform: translate3d(-4px, 0, 0);
    }
  }
}
a {
  outline: none;
}
</style>
